<template>
  <v-container id="home_menu" fluid tag="section">
    <v-col cols="12" md="12">
      <!-- วิทยาลัย ประเมิน ภาค ก รองผู้อำนวยการ  -->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="period_college_redirector_A.period_college_enable === '1'"
        >
          <v-row>
            <v-col cols="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h2>
                      ประเมิน<u>
                        ภาค ก คัดเลือก ตำแหน่งรองผู้อำนวยการวิทยาลัย</u
                      >
                    </h2>
                    <h3>
                      ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                      {{
                        period_college_redirector_A.period_college_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                    <h4>
                      * เฉพาะวิทยาลัยที่ปรากฎผู้สมัคร
                    </h4>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn color="primary" to="/college/sedirector_app"
                      >ดำเนินการ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ วันที่ :
                  {{
                    period_college_redirector_A.period_college_stop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="
                    period_college_redirector_A.period_college_stop +
                      ' 16:30:00'
                  "
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <!-- วิทยาลัยรายงานตัว รองผู้อำนวยการ  -->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="period_college_redirector.period_college_enable === '1'"
        >
          <v-row>
            <v-col cols="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h2>รับรายงานตัว<u> รองผู้อำนวยการวิทยาลัย</u></h2>
                    <h3>
                      ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                      {{
                        period_college_redirector.period_college_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                    <h4>
                      * เฉพาะวิทยาลัยที่ได้รับ รองผู้อำนวยการวิทยาลัย
                    </h4>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn color="primary" to="/college/sedirector_app_report"
                      >ดำเนินการ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ วันที่ :
                  {{
                    period_college_redirector.period_college_stop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="
                    period_college_redirector.period_college_stop + ' 16:30:00'
                  "
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <!-- วิทยาลัยรายงานตัว แจ้งสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง  -->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="period_teacher_manpower.period_college_enable === '1'"
        >
          <v-row>
            <v-col cols="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h2><u> แจ้งสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง</u></h2>
                    <h3>
                      ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                      {{
                        period_teacher_manpower.period_college_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn color="primary" to="/college/man_power_j"
                      >ดำเนินการ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ วันที่ :
                  {{
                    period_teacher_manpower.period_college_stop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="
                    period_teacher_manpower.period_college_stop + ' 16:30:00'
                  "
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <v-card v-if="nullsCounts >= 1">
        <v-alert type="error">
          <v-row>
            <v-col class="grow">
              <h2>
                ยังไม่ได้ดำเนินการแต่งตั้งคณะกรรมการประเมินครูผู้ช่วย จำนวน
                {{ nullsCounts }} ราย
              </h2>
            </v-col>
            <v-col class="shrink">
              <v-btn color="success" to="/college/assistantcommitteequalified"
                >ดำเนินการ</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </v-card>

      <!-- ปรับปรุงข้อมูลข้าราชการครู -->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="periodassteachs.periodAssTeachEnable === '1'"
        >
          <v-row>
            <v-col cols="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h2>
                      ดำเนินการ
                      <u v-if="periodassteachs.periodAssTeachTimes >= 1"
                        >รับรายงานตัวครูผู้ช่วย รอบ
                        {{ periodassteachs.periodAssTeachTimes }} :
                        {{ periodassteachs.periodAssTeachYear }}</u
                      >
                      <u v-else>รับรายงานตัวครูผู้ช่วย รอบคัดเลือกภายใน</u>
                    </h2>
                    <h3>
                      ก่อนระบบปิดในวันที่
                      {{
                        periodassteachs.periodAssTeachStop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                  </v-col>

                  <v-col class="shrink">
                    <v-btn
                      color="primary"
                      to="/college/manage_assistant_teacher"
                      >ดำเนินการ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ :
                  {{
                    periodassteachs.periodAssTeachStop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="periodassteachs.periodAssTeachStop + ' 16:30:00'"
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <!-- ปรับปรุงข้อมูลข้าราชการครู -->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="
            periodTeachers.period_enable === '1' &&
              periodTeachers.period_type === 'teacher'
          "
        >
          <v-row>
            <v-col cols="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h2>
                      ดำเนินการ
                      <u>ปรับปรุงข้อมูลข้าราชการครู เพื่อใช้ในการเสนอย้าย </u>
                    </h2>
                    <h3>
                      ก่อนระบบปิดในวันที่
                      {{
                        periodTeachers.period_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                  </v-col>

                  <v-col class="shrink">
                    <v-btn color="primary" to="/college/personnel_tem"
                      >ดำเนินการ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                  {{
                    periodTeachers.period_stop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="periodTeachers.period_stop + ' 16:30:00'"
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <!-- แจ้งเตือนเงือนไขการย้าย -->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="
            period_college_moves.period_college_enable === '1' &&
              period_college_moves.period_college_type === 'movement_college'
          "
        >
          <v-row>
            <v-col cols="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h2>
                      ดำเนินการ
                      <u>แจ้งเงื่อนไขสาขาวิชาและจำนวนที่ต้องการรับย้าย </u>
                    </h2>
                    <h3>
                      ก่อนระบบปิดในวันที่
                      {{
                        period_college_moves.period_college_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                  </v-col>
                  <v-col
                    class="shrink"
                    v-if="conditions_transfers.documentsLinkfile"
                  >
                    <v-btn
                      color="info"
                      :href="
                        '/HRvecfiles/' + conditions_transfers.documentsLinkfile
                      "
                      target="_blank"
                      >เสร็จสิ้น</v-btn
                    >
                  </v-col>
                  <v-col class="shrink" v-else>
                    <v-btn color="primary" to="/college/conditions_branch"
                      >ดำเนินการ</v-btn
                    >
                    <v-btn text color="red" @click="VideoDialog()">
                      <v-icon large color="red">mdi-video</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                  {{
                    period_college_moves.period_college_stop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="
                    period_college_moves.period_college_stop + ' 16:30:00'
                  "
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <!-- แจ้งเตือนเงือนไขการย้าย แนบไฟล์-->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="
            period_college_moves_attachment.period_college_enable_file ===
              '1' &&
              period_college_moves_attachment.period_college_type ===
                'movement_college'
          "
        >
          <v-row>
            <v-col cols="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h2>
                      ดำเนินการ
                      <u>แนบไฟล์เงือนไขสาขาวิชา </u>
                    </h2>
                    <h3>
                      ก่อนระบบปิดในวันที่
                      {{
                        period_college_moves_attachment.period_college_attactmentstop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                  </v-col>
                  <v-col
                    class="shrink"
                    v-if="conditions_transfers.documentsLinkfile"
                  >
                    <v-btn
                      color="info"
                      :href="
                        '/HRvecfiles/' + conditions_transfers.documentsLinkfile
                      "
                      target="_blank"
                      >เสร็จสิ้น</v-btn
                    >
                  </v-col>
                  <v-col class="shrink" v-else>
                    <v-btn color="primary" to="/college/conditions_branch"
                      >ดำเนินการ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                  {{
                    period_college_moves_attachment.period_college_attactmentstop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="
                    period_college_moves_attachment.period_college_attactmentstop +
                      ' 16:30:00'
                  "
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <!-- แจ้งเตือนรวบรวมแบบคำร้องขอย้าย -->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="
            period_teacher_requests.period_college_enable === '1' &&
              period_teacher_requests.period_college_type === 'teacher_request'
          "
        >
          <v-row>
            <v-col cols="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h2>
                      ดำเนินการ
                      <u>แนบไฟล์แบบคำร้องขอย้ายและเอกสารประกอบการพิจารณา </u>
                    </h2>
                    <h3>
                      ก่อนระบบปิดในวันที่
                      {{
                        period_teacher_requests.period_college_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn color="primary" to="/college/transference_personnel"
                      >ดำเนินการ</v-btn
                    >
                    <v-btn text color="red" @click="VideoDialogCondition()">
                      <v-icon large color="red">mdi-video</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                  {{
                    period_teacher_requests.period_college_stop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="
                    period_teacher_requests.period_college_stop + ' 16:30:00'
                  "
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <!-- พิจารณารับยายสายบริหาร -->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="
            perioddiscuss.period_college_enable === '1' &&
              perioddiscuss.period_college_type === 'discuss_college'
          "
        >
          <v-row>
            <v-col cols="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h3>
                      ดำเนินการขอความคิดเห็นจากคณะกรรมการวิทยาลัย
                      <u>ต่อผู้ประสงค์ขอย้ายเข้า รับย้ายสายบริหาร</u>
                    </h3>
                    <h3>
                      ก่อนระบบปิดในวันที่
                      {{
                        perioddiscuss.period_college_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      color="primary"
                      to="/college/transference_manage_location"
                      >ดำเนินการ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ รายงานผลการพิจารณา <u>รับย้ายสายบริหาร</u> :
                  {{
                    perioddiscuss.period_college_stop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="perioddiscuss.period_college_stop + ' 16:30:00'"
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <!-- พิจารณารับย้ายสายการสอนครู -->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="
            period_teacher_moves.period_college_enable === '1' &&
              period_teacher_moves.period_college_type === 'discuss_teacher'
          "
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h3>
                      ดำเนินการขอความคิดเห็นจากคณะกรรมการวิทยาลัย
                      <u>ต่อผู้ประสงค์ขอย้ายเข้า รับย้ายสายการสอน (ครู)</u>
                    </h3>
                    <h3>
                      ก่อนระบบปิดในวันที่
                      {{
                        period_teacher_moves.period_college_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      color="primary"
                      to="/college/transference_personnel_movein"
                      >ดำเนินการ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ รายงานผลการพิจารณา <u>รับย้ายสายการสอน</u> :
                  {{
                    perioddiscuss.period_college_stop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="
                    period_teacher_moves.period_college_stop + ' 16:30:00'
                  "
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <!-- วิทยาลัยรายงานอัตรากำลัง  -->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="
            period_colleges.period_college_enable === '1' &&
              period_colleges.period_college_type === 'update_college'
          "
        >
          <v-row>
            <v-col cols="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h2>รายงาน<u>อัตรากำลัง</u></h2>
                    <h3>
                      ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                      {{
                        period_colleges.period_college_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn color="primary" to="/college/personnel_etc"
                      >ดำเนินการ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ งานอัตรากำลังวันที่ :
                  {{
                    period_colleges.period_college_stop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="period_colleges.period_college_stop + ' 16:30:00'"
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>

      <!-- วิทยาลัยรายงานตัว ครูย้าย  -->
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="pa-2"
          v-if="
            period_colleges_reportmv.period_college_enable === '1' &&
              period_colleges_reportmv.period_college_type ===
                'reportmove_teacher'
          "
        >
          <v-row>
            <v-col cols="6">
              <v-alert
                outlined
                prominent
                icon="mdi-bell-ring"
                type="error"
                border="left"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="blue--text">
                      <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                    </h2>
                    <h2>รับรายงานตัว<u>ครูย้ายกรณีปกติ</u></h2>
                    <h3>
                      ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                      {{
                        period_colleges_reportmv.period_college_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      color="primary"
                      to="/college/conditons_transfer_success"
                      >ดำเนินการ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="6">
              <div class="text-center">
                <h3>
                  ปิดระบบ งานอัตรากำลังวันที่ :
                  {{
                    period_colleges_reportmv.period_college_stop
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                  <br />
                  เหลือเวลาอีก
                </h3>
                <flip-countdown
                  :deadline="
                    period_colleges_reportmv.period_college_stop + ' 16:30:00'
                  "
                  :labels="labels"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>
    </v-col>

    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      border="left"
      colored-border
      elevation="2"
    >
      <v-row>
        <v-col cols="12" md="3"> </v-col>
        <v-col cols="12" md="3">
          <div>
            <h2>ผู้ใช้งานระบบ : {{ user.user_ID }}</h2>
          </div>
          <div>
            <h3>
              ชื่อผู้ใช้งาน : {{ user.user_firstname }}
              {{ user.user_lastname || " " }}
            </h3>
          </div>
          <h3>สถานะ : {{ user.user_status }}</h3>
        </v-col>
        <v-col cols="12" md="3"> </v-col>
      </v-row>
    </v-alert>
    <div class="text-center pa-2">
      <!--   <v-btn rounded outlined color="warning" class="mr-1" href="#userlogin"
        >ผู้ใช้งานระบบ</v-btn
      >
      <v-btn rounded outlined color="error" class="mr-1" href="#college"
        >ข้อมูลวิทยาลัย</v-btn
      >
      <v-btn rounded outlined color="deep-purple accent-4" class="mr-1" href="#personnel"
        >ข้อมูลบุคคล</v-btn
      > -->
      <v-btn rounded outlined color="info darken-2" class="mr-1" href="#t38MV"
        >ย้าย บุคลากรอื่น 38 ค.(2)</v-btn
      >

      <v-btn
        rounded
        outlined
        color="green darken-4"
        class="mr-1"
        href="#condition"
        >เงือนไขสาขาวิชารับย้าย</v-btn
      >
      <v-btn rounded outlined color="info" class="mr-1" href="#teacherMV"
        >ย้ายครู</v-btn
      >
      <v-btn rounded outlined color="brown" class="mr-1" href="#manageMV"
        >ย้ายผู้บริหาร</v-btn
      >
      <v-btn
        rounded
        outlined
        color="orange darken-4"
        class="mr-1"
        href="#manpower"
        >ระบบอัตรากำลัง</v-btn
      >
      <v-btn
        rounded
        outlined
        color="lime-darken-4"
        class="mr-1"
        href="#assisstant"
        >ครูผู้ช่วย</v-btn
      >

      <v-btn
        v-if="period_college_redirector_A.period_college_enable === '1'"
        rounded
        outlined
        color="lime-darken-4"
        class="mr-1"
        href="#assisstant_dutyDirector"
        >คัดเลือก รองผู้อำนวยการ</v-btn
      >

      <!--  <v-btn rounded outlined color="mdi-human-male-male" class="mr-1" href="#reports"
        >งานรับรายงานตัว</v-btn
      > -->
    </div>
    <v-row no-gutters>
      <v-col cols="12" md="12" class="pa-1">
        <v-alert
          border="left"
          colored-border
          type="error"
          elevation="2"
          icon="mdi-account-cog"
          id="userlogin"
        >
          <v-card-title>ผู้ใช้งานระบบ</v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in user_system_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>
      <v-col cols="12" md="12" class="pa-1">
        <v-alert
          border="left"
          colored-border
          type="error"
          elevation="2"
          icon="mdi-account-cog"
          id="college"
        >
          <v-card-title>ข้อมูลวิทยาลัย</v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in college_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>
      <v-col cols="12" md="12" class="pa-1">
        <v-alert
          border="left"
          colored-border
          color="deep-purple accent-4"
          elevation="2"
          icon="mdi-account-details"
          id="personnel"
        >
          <v-card-title>ข้อมูลงานบุคคล</v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in user_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>

      <v-col cols="12" md="12" class="pa-1">
        <v-alert
          border="left"
          colored-border
          type="info"
          elevation="2"
          icon="mdi-account-convert"
          id="t38MV"
        >
          <v-card-title
            >รายงานการเสนอย้าย บุคลากรทางการศึกษาอื่นตามมาตรา 38
            ค.(2)</v-card-title
          >
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in movement38_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>

      <v-col cols="12" md="12" class="pa-1">
        <v-alert
          border="left"
          colored-border
          color="green darken-4"
          elevation="2"
          icon="mdi-source-branch"
          id="condition"
        >
          <v-card-title
            >เงือนไขสาขาวิชา

            <v-spacer></v-spacer>
            <v-btn text color="red" @click="VideoDialogConditionB()">
              <v-icon large color="red">mdi-video</v-icon>
            </v-btn>
          </v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in condition_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>

      <v-col cols="12" md="12" class="pa-1">
        <v-alert
          border="left"
          colored-border
          type="info"
          elevation="2"
          icon="mdi-account-convert"
          id="teacherMV"
        >
          <v-card-title>รายงานการเสนอย้าย สายการสอน (ครู)</v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in movement_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>

      <v-col cols="12" md="12" class="pa-1">
        <v-alert
          border="left"
          colored-border
          elevation="2"
          icon="mdi-account-convert"
          id="manageMV"
        >
          <v-card-title
            >รายงานผู้บริหารเสนอย้าย
            <h3 class="red--text">ขอย้ายเข้า</h3>
            <v-spacer></v-spacer>
            <v-btn text color="red" @click="VideoDialogManageMove()">
              <v-icon large color="red">mdi-video</v-icon>
            </v-btn>
          </v-card-title>

          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in movementmanageout_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="pa-1"
        v-if="period_college_redirector_A.period_college_enable === '1'"
      >
        <v-alert
          border="left"
          colored-border
          elevation="2"
          icon="mdi-account-convert"
          id="assisstant_dutyDirector"
        >
          <v-card-title
            >คัดเลือก ตำแหน่งรองผู้อำนวยการวิทยาลัย
            <v-spacer></v-spacer>
          </v-card-title>

          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in sedirector_app_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="pa-1"
        v-if="periods.period_enable_process === '1'"
      >
        <v-alert
          border="left"
          colored-border
          elevation="2"
          icon="mdi-account-convert"
        >
          <v-card-title
            >รายงานผู้บริหารเสนอย้าย
            <h3 class="red--text">ขอย้ายออก</h3>
          </v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in movementmanagein_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>

      <v-col cols="12" md="12" class="pa-1">
        <v-alert
          border="left"
          colored-border
          color="orange darken-4"
          elevation="2"
          icon="mdi-human"
          id="manpower"
          ><v-card-title>ระบบอัตรากำลัง</v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in human_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>
      <v-col cols="12" md="12" class="pa-1">
        <v-alert
          border="left"
          colored-border
          color="yellow accent-4"
          elevation="2"
          icon="mdi-human-male-male"
          id="assisstant"
        >
          <v-card-title
            >ระบบครูผู้ช่วย

            <v-spacer></v-spacer>
            <v-btn text color="red" @click="VideoDialogAssessmentDev()">
              <v-icon large color="red">mdi-video</v-icon>
            </v-btn>
          </v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in prepare_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>    

      <v-col cols="12" md="12" class="pa-1">
        <v-alert
          border="left"
          colored-border
          color="#6495ED"
          elevation="2"
          icon="mdi-human-male-male"
          id="reports"
        >
          <v-card-title>รับรายงานตัว </v-card-title>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="item in sedirector_items"
                :key="item.i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-alert>
      </v-col>
    </v-row>

    <!-- V-model userdialog -->
    <v-layout row justify-center>
      <v-dialog v-model="adduserdialog" persistent max-width="80%">
        <v-card class="mx-auto pa-6" elevation="2">
          <base-material-card
            color="yellow"
            icon="mdi-clipboard-text"
            title="เปลี่ยนรหัสผ่าน"
            class="px-5 py-3 text_google"
            elevation="2"
          >
          </base-material-card>
          <v-card-text>
            <v-form ref="userform" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md12>
                    {{ user.user_name }} {{ user.user_firstname
                    }}{{ user.user_lastname }}
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      outlined
                      label="Password"
                      v-model="user.user_password"
                      type="password"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      outlined
                      label="Confirm Password"
                      v-model="user.user_confirmpassword"
                      type="password"
                      required
                      :rules="[v => v == user.user_password]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
              </v-container>
              <small>* จำเป็น</small>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large @click.stop="adduserdialog = false" rounded>
              <v-icon dark>mdi-close</v-icon>ยกเลิก
            </v-btn>
            <v-btn large color="warning" @click.stop="userSubmit()" rounded>
              <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไข
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- Video Dialog -->
    <v-layout>
      <v-dialog
        v-model="VideoDialogYoutubeManageMovement"
        persistent
        max-width="80%"
      >
        <v-card>
          <v-card-text>
            <v-card>
              <v-card-text>
                <iframe
                  width="100%"
                  height="600px"
                  src="https://www.youtube.com/embed/Tg9DjFm6SDM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              rounded
              @click.stop="VideoDialogYoutubeManageMovement = false"
              ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- Video Dialog -->
    <v-layout>
      <v-dialog
        v-model="VideoDialogYoutubeConditionbranch"
        persistent
        max-width="80%"
      >
        <v-card>
          <v-card-text>
            <v-card>
              <v-card-text>
                <iframe
                  width="100%"
                  height="600px"
                  src="https://www.youtube.com/embed/iVZy90tWSZg"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              rounded
              @click.stop="VideoDialogYoutubeConditionbranch = false"
              ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- Video Dialog -->
    <v-layout>
      <v-dialog
        v-model="VideoDialogYoutubeAssessmentDevTeach"
        persistent
        max-width="80%"
      >
        <v-card>
          <v-card-text>
            <v-card>
              <v-card-text>
                <iframe
                  width="100%"
                  height="600px"
                  src="https://www.youtube.com/embed/IuTKn-M-KGY"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              rounded
              @click.stop="VideoDialogYoutubeAssessmentDevTeach = false"
              ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  components: { FlipCountdown },
  data() {
    return {
      ApiKey: "HRvec2021",
      user: [],
      periods: [],
      adduserdialog: false,
      VideoDialogYoutubeManageMovement: false,
      VideoDialogYoutubeConditionbranch: false,
      VideoDialogYoutubeAssessmentDevTeach: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      user_system_items: [
        {
          text: "ข้อมูลผู้ใช้งานระบบ ข้าราชการครูและบุคลาการทางการศึกษา",
          icon: "mdi-account-arrow-right",
          to: "/college/personnel"
        }
      ],

      sedirector_app_items: [
        {
          text: "คัดเลือก ตำแหน่งรองผู้อำนวยการ",
          icon: "mdi-information",
          to: "/college/sedirector_app"
        }
      ],

      college_items: [
        {
          text: "ข้อมูลรายละเอียดวิทยาลัย",
          icon: "mdi-information",
          to: "/college/collegeinfo"
        }
      ],

      user_items: [
        {
          text: "ข้อมูลข้าราชการครูและบุคลากรทางการศึกษา (จากส่วนกลาง)",
          icon: "mdi-account",
          to: "/college/personnel_tem"
        },
        {
          text:
            "ข้อมูลข้าราชการครูและบุคลากรทางการศึกษา (วิทยาลัย) งานอัตรากำลัง",
          icon: "mdi-account-settings",
          to: "/college/personnel_etc"
        },
        {
          text: "ข้อมูลคุณวุฒิการศึกษา",
          icon: "mdi-school",
          to: "/college/personnel_education"
        },
        {
          text: "คัดเลือก/แข่งขัน บุคลากร",
          icon: "mdi-account-search",
          to: "/college/temporarycollegeemreg"
        }
      ],
      movement_items: [
        {
          text: "สายการสอนประสงค์ย้ายออก",
          icon: "mdi-account-circle",
          to: "/college/transference_personnel"
        },
        {
          text: "สายการสอนประสงค์ย้ายเข้า",
          icon: "mdi-account-circle",
          to: "/college/transference_personnel_movein"
        }
      ],

      movement38_items: [
        {
          text: "ประสงค์ย้ายออก",
          icon: "mdi-account-circle",
          to: "/college/transference_personnel_38"
        },
        {
          text: "ประสงค์ย้ายเข้า",
          icon: "mdi-account-circle",
          to: "/college/transference_personnel_movein_38"
        }
      ],

      movementmanagein_items: [
        {
          text: "สายบริหารในวิทยาลัยยืนย้าย",
          icon: "mdi-account-circle",
          to: "/college/transference_manage"
        }
      ],

      movementmanageout_items: [
        {
          text: "สายบริหารวิทยาลัยอื่นประสงค์ย้าย",
          icon: "mdi-account-circle",
          to: "/college/transference_manage_location"
        }
      ],

      condition_items: [
        {
          text: "ข้อมูลเงือนไขสาขาวิชาเอก",
          icon: "mdi-source-branch",
          to: "/college/conditions_branch"
        },
        {
          text: "ประวัติข้อมูลเงืนอไขสาขาวิชาเอก",
          icon: "mdi-source-branch",
          to: "/college/history_conditions"
        }
      ],

      human_items: [
        {
          text: "กำหนดสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง",
          icon: "mdi-account-multiple",
          to: "/college/man_power_j"
        },
        {
          text: "ข้อมูลทั่วไป",
          icon: "mdi-account-multiple",
          to: "/college/rate_workforce_g"
        },
        {
          text: "ข้อมูลอัตรากำลังวิทยาลัย",
          icon: "mdi-account-multiple",
          to: "/college/rate_workforce"
        },
        {
          text: "ข้อมูลอัตรากำลังวิทยาลัยสรุป",
          icon: "mdi-account-multiple",
          to: "/college/rate_workforce_pro"
        }
      ],

      prepare_items: [
        {
          text: "รับรายงานตัวครูผู้ช่วย",
          icon: "mdi-account-star",
          to: "/college/manage_assistant_teacher"
        },
        {
          text: "การแต่งตั้งคณะกรรมการประเมิน",
          icon: "mdi-account-star",
          to: "/college/assistantcommitteequalified"
        },
        {
          text: "รายงานผลการประเมิน",
          icon: "mdi-account-star",
          to: "/college/assistant_teacher"
        }
      ],
      sedirector_items: [
        {
          text: "รับรายงานตัว ตำแหน่ง รองผู้อำนวยการ",
          icon: "mdi-account-star",
          to: "/college/sedirector_app_report"
        },
        {
          text: "รับรายงานตัว ตำแหน่ง ครูผู้ช่วย",
          icon: "mdi-account-star",
          to: "/college/manage_assistant_teacher"
        }
      ],

      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      period_colleges: [],
      period_college_moves: [],
      period_college_moves_attachment: [],
      period_teacher_moves: [],
      period_teacher_manpower: [],
      perioddiscuss: [],
      periodTeachers: [],
      periodManages: [],
      period_teacher_requests: [],
      period_vecprovinceTeacher: [],
      period_vecprovincemanage: [],
      periodassteachs: [],
      period_colleges_reportmv: [],
      conditions_transfers: [],
      manage_assistant_teachers: [],
      nullsCounts: [],
      period_college_redirector: [],
      period_college_redirector_A: [],
      period_college_redirector_A: []
    };
  },

  async mounted() {
    await this.periodQuery();
    await this.getuser();
    await this.manage_assistant_teacherQueryAll();

    await this.periodassteachQuery(); /* รายงานตัวครูผู้ช่วย */
    await this.periodQueryTeacher(); /* ย้ายครู */
    await this.periodQueryManage(); /* ย้ายผู้บริหาร */
    await this.teacher_requestQuery(); /* รวบรวมแบบคำร้องขอย้ายครู */
    await this.period_college_moveQuery(); /* เงือนไขการย้าย */
    await this.period_teacher_moveQuery(); /* ขอความเห็นจากคณะกรรมการวิทยาลัยย้ายสายการสอน */
    await this.periodDiscussQuery(); /* ขอความเห็นจากคณะกรรมการวิทยาลัยย้ายสายบริหาร */
    await this.period_collegeQuery(); /* อัตรากำลัง */
    await this.period_collegemvQuery(); /* รายงานตัว ครูย้าย */
    await this.period_collegeSedirectors();
    await this.period_college_move_attachment();
    await this.period_collegeSedirectors_A();
    await this.period_collegeManpower();
  },

  methods: {
    async period_collegeManpower() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "701"
      });
      this.period_teacher_manpower = result_period_college.data;
    },

    async period_collegeSedirectors_A() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "20100"
      });
      this.period_college_redirector_A = result_period_college.data;
    },

    async period_collegeSedirectors() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "20101"
      });
      this.period_college_redirector = result_period_college.data;
    },

    async manage_assistant_teacherQueryAll() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_college_code: this.user.user_code,
        mt_admissions_status: "receipt"
      });
      this.manage_assistant_teachers = result.data;
      if (this.manage_assistant_teachers.length > 0) {
        let data = this.manage_assistant_teachers;
        let sum = 0;
        let countcf = 0;
        data.forEach(value => {
          sum += 1;
          if (value.mt_committe_status == "CF") {
            countcf += 1;
          }
        });
        let nullsCounts = sum - countcf;
        this.nullsCounts = parseInt(nullsCounts);
      }
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async teacher_requestQuery() {
      let result;
      result = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "teacher_request"
      });
      this.period_teacher_requests = result.data;
    },
    async period_college_moveQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "movement_college"
      });
      this.period_college_moves = result_period_college.data;
    },

    async period_college_move_attachment() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable_file: "1",
        period_college_type: "movement_college"
      });
      this.period_college_moves_attachment = result_period_college.data;
    },

    async period_teacher_moveQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "discuss_teacher"
      });
      this.period_teacher_moves = result_period_college.data;
    },

    async periodDiscussQuery() {
      let result_period;
      result_period = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "discuss_college"
      });
      this.perioddiscuss = result_period.data;
    },

    async period_collegeQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "update_college"
      });
      this.period_colleges = result_period_college.data;
    },

    async period_collegemvQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "reportmove_teacher"
      });
      this.period_colleges_reportmv = result_period_college.data;
    },

    async periodassteachQuery() {
      let result;
      result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachEnable: "1"
      });
      this.periodassteachs = result.data;
    },

    async periodQueryTeacher() {
      let result;
      result = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "1",
        period_type: "teacher"
      });
      this.periodTeachers = result.data;
    },

    async periodQueryManage() {
      let result;
      result = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "1",
        period_type: "manage"
      });
      this.periodManages = result.data;
    },
    async getuser() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        ApiKey: "HRvec2021"
      });
      this.user = result.data;
    },

    async VideoDialogAssessmentDev() {
      this.VideoDialogYoutubeAssessmentDevTeach = true;
    },

    async VideoDialogConditionB() {
      this.VideoDialogYoutubeConditionbranch = true;
    },
    async VideoDialogManageMove() {
      this.VideoDialogYoutubeManageMovement = true;
    },

    async userUpdate() {
      this.adduserdialog = true;
    },

    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_enable: "1",
        period_type: "manage",
        periord_admin: "check"
      });
      this.periods = result_period.data;
    },

    async userSubmit() {
      if (this.$refs.userform.validate()) {
        this.user.ApiKey = this.ApiKey;
        let result;
        result = await this.$http.post("user.update_password.php", this.user);
        if (result.status == "200") {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.adduserdialog = false;
      }
    }
  }
};
</script>
<style>
.listcontent {
  background-color: #750606;
  border-bottom: 3px solid #d68822;
  height: 100px;
}
</style>
